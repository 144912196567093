import Vue from "vue"
import Vuex from "vuex"

import axios from "axios" // ajax library
import _ from "lodash"
import axiosDefaults from "axios/lib/defaults"

import storeUtils from "./utils"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    banners: [],
    profilePic: null,
    timeslotRequest: [],
    countriesAll: [], // for register / freeTrial
    withTBSMembership: false, 
    categoryOptions: [
      { id: 1, value: "PC/Laptops" },
      { id: 2, value: "Printers" },
      { id: 3, value: "Server/Storage" },
      { id: 4, value: "Networking/Telecom" },
      { id: 5, value: "Software" },
      { id: 6, value: "Electronics" },
      { id: 7, value: "Mobile" },
      { id: 8, value: "POS/BarCode/Banking" },
      { id: 9, value: "Components" },
    ],
    keynotes: {
      // 5: `CANCELLED: <span class='text-decoration: line-through'>Key Note by Bob Lafon Capital Growth Partners - "Future Proofing Your Business: How to be relevant now and in the future."</span>`,
      // 8: `Key Note by Christoffer Sandell Inrego - "Increase your sales using sustainability"`,
    },
    scannedCode: null,
  },
  getters: {
    bannersEnriched: state => state.banners,
    userPicCSS: state => ({
      background: `url('${state.profilePic}') no-repeat right center / cover`,
    }),
    countriesOptionsPleaseSelect: state => {
      const countryOptions = _.map(state.countriesAll, countryDTO => ({
        value: countryDTO.country_id,
        name: countryDTO.country,
      }))
      return [{ value: "", name: "Please Select Country" }].concat(
        // eslint-disable-next-line comma-dangle
        countryOptions
      )
    },
    scannedCode: (state) => state.scannedCode,
  },
  mutations: {
    // Called on app startup + login
    updateUser(state, user) {
      if (user.doLogout) return this.dispatch("signout")
      localStorage.setItem("user", JSON.stringify(user))
      state.user = user
      axiosDefaults.headers.Authorization = `Bearer ${user.token}`
      return null
    },
    removeUser(state) {
      localStorage.removeItem("user")
      state.user = null
      delete axiosDefaults.headers.Authorization
    },
    updateProfilePic(state, profilePicBase64) {
      localStorage.setItem("profilePic", profilePicBase64)
      state.profilePic = profilePicBase64
    },
    removeProfilePic(state) {
      localStorage.removeItem("profilePic")
      state.profilePic = null
    },
    updateCountriesAll(state, results) {
      state.countriesAll = results
    },
    updateBanners(state, banners) {
      state.banners = banners
    },
    bootstrapUserFromLocalStorage(state) {
      const userStr = localStorage.getItem("user")
      if (!userStr) return

      const user = JSON.parse(userStr)

      state.user = user
      axiosDefaults.headers.Authorization = `Bearer ${user.token}`
    },
    setTimeslotRequest(state, timeslot) {
      state.timeslotRequest = timeslot;
    },
    setScannedCode(state, code) {
      state.scannedCode = code;
    },    
    setWithTBSMembership(state, value) {
      state.withTBSMembership = value
    },
  },
  actions: {
    async checkUserData() {
      const userData = JSON.parse(localStorage.getItem('user'));
      
      if (userData) {
        const { id, email, companyId } = userData;
        try {
          const response = await axios.post('/api/register/validateUser', { email, companyId, id });

          if (!response.data.isValid) {
            localStorage.removeItem("user");
            this.logout();
          }
        } catch (error) {
          console.error('Error checking user data:', error);
        }
      } else {
        console.log('No user data found in localStorage.');
      }
    },
    async signin({ commit }, payloadIn = {}) {
      const payload = await axios.post("/api/auth/login", payloadIn)
      if (payload.status !== 200) {
        throw _.extend(new Error("Failure to login"), {
          status: payload.status,
        })
      }
      commit("updateUser", payload.data)
    },

    signout({ commit }) {
      commit("removeUser")
    },

    // TODO: CHECK
    // Request password. An email is send with a link
    requestPassword(store, payload = {}) {
      return axios.post("/api/companies/requestPassword", payload)
    },

    // TODO: CHECK
    // Verify reset token. Used when clicking on password reset link in email
    verifyResetToken(store, payload = {}) {
      return axios.post("/api/companies/verifyResetToken", payload)
    },

    // TODO: CHECK
    resetPassword(store, payload = {}) {
      if (payload.token) {
        // Other way to reset password. Now with token.
        // This is called when user does a password reset while logged-in
        return axios.post("/api/companies/resetPasswordWithToken", payload)
      }
      // After reset token is verified (verifyResetToken above) -> update new password
      // This is called when doing password reset when not logged in, but with token received from email
      return axios.post("/api/companies/resetPassword", payload)
    },

    loadProfilePicture({ commit }) {
      return axios.get(`/api/companies/fetchPicture`).then(resp => {
        if (resp.data.profilePic) {
          commit("updateProfilePic", resp.data.profilePic)
        } else {
          commit("removeProfilePic")
        }
      })
    },
    uploadPicture({ commit, state }, payload = {}) {
      return axios.post(`/api/companies/uploadPicture`, payload).then(resp => {
        commit("updateProfilePic", resp.data.profilePic)
        return resp
      })
    },
    removePicture({ commit, state }) {
      return axios.post(`/api/companies/removePicture`).then(resp => {
        commit("removeProfilePic")
        return resp
      })
    },

    getCountriesAll({ commit }, payload = {}) {
      return axios.get(`/api/misc/countriesAll`).then(({ data }) => {
        commit("updateCountriesAll", data.payload)
      })
    },

    async register(store, payloadIn = {}) {
      const payload = await axios.post("/api/register", payloadIn)
      if (payload.data.status !== 200) {
        throw _.assign(new Error(payload.data.error || "Failure to send the registration request."), {
          status: payload.data.status,
        })
      }
      return payload.data
    },

    getRandomBanners({ commit }) {
      return axios.get(`/api/misc/randomBanners`).then(({ data }) => {
        commit("updateBanners", data.payload)
      })
    },

    incrementClickForBanner({ commit }, id) {
      axios.get(`/api/misc/randomBanners/${id}/incrementNumClicked`)
    },

    sendMailToTBS({ commit, state }, payload = {}) {
      return axios.post(`/api/mail/sendMailToTBS`, payload)
    },
    sendMailToContact({ commit, state }, payload = {}) {
      return axios.post(`/api/mail/sendMailToContact`, payload)
    },
    getCompany({ commit, dispatch }, id) {
      // getCompany is called in pre-hook, so we need to make sure user is bootstrapped. (This is done in app.created, which is called later)
      commit("bootstrapUserFromLocalStorage")

      return axios.get(`/api/companies/${id}`).then(({ data }) => {
        if (data.status !== 200) return { status: data.status }
        return storeUtils.enrichProfile(data)
      })
    },
    updateCompany({ commit, state }, payload = {}) {
      return axios.post(`/api/companies/updateCompany`, payload)
    },
    updateContact({ commit, state }, payload = {}) {
      return axios.post(`/api/companies/updateContact`, payload)
    },

    updateTimeslotRequest({ commit }, timeslot) {
      commit('setTimeslotRequest', timeslot);
    },

    updateScannedCode({ commit }, code) {
      commit('setScannedCode', code);
    },    

    trackGA4Event(context, payload) {  
      try {
        if (typeof gtag === "function") { 

          const referrer = localStorage.getItem("referrer") || "Direct";

          window.gtag('event', payload.event, {
              event_category: payload.event_category,
              event_label: payload.event_label,
              action: payload.action,
              message: payload.message,
              user_id: payload.user_id || context.state.user?.id || null,
              heard_through: payload.heardThrough,
              step: payload.step,
              tbs_member: payload.withTBSMembership,
              referrer: referrer || 'Direct',
              external_exit: payload.external_exit,
          });
        } else {
          console.warn('Google Analytics not loaded yet');
        }
      } catch (error) {
          console.error('Error tracking GA4 event:', error);
          // Don't throw the error to prevent app disruption
      }
    }
  
  },

  modules: {},
})
