<template>
  <base-layout :do-skip-header="false">
    <template #main>
      <div class="bg-white">
        <div class="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
          <div class="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-2">
            <div class="space-y-5 sm:space-y-4">
              <h2 class="text-2xl sm:text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl mb-2">Get in touch</h2>
              <p class="text-xl leading-7 text-gray-500">We're happy to help. And please remember, trade safe!</p>
            </div>
            <div class="lg:col-span-2">
              <ul class="space-y-4 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:col-gap-8">
                <li v-for='contact in contacts' :key='contact.name'>
                  <div class="flex items-center space-x-4 lg:space-x-6 py-4 sm:py-0">
                    <img class="w-16 h-16 rounded-full lg:w-20 lg:h-20" :src="contact.picUrl" alt="">
                    <div class="font-medium text-lg leading-6 space-y-1 ml-4">

                      <a href='javascript:void(0)' @click='$refs.contactModal.openSendMessageModal(contact)'>
                        <h4>
                          <svg class="h-6 w-6 text-gray-400 float-left mr-2" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                          </svg>
                          {{ contact.name }}
                        </h4>
                        <p class="text-red-600">{{ contact.position }}</p>
                      </a>
                      <p class="text-red-600"><a :href="contact.telHref">{{ contact.tel }}</a></p>
                      <div class="float-left mr-2">
                        <a :href='"whatsapp://send?phone=" + formatWhatsAppNumber(contact.tel)'>
                          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                            viewBox="0 0 455.731 455.731" width="20" height="20" xml:space="preserve">
                          <g>
                            <rect x="0" y="0" style="fill:#1BD741;" width="455.731" height="455.731"/>
                            <g>
                              <path style="fill:#FFFFFF;" d="M68.494,387.41l22.323-79.284c-14.355-24.387-21.913-52.134-21.913-80.638
                                c0-87.765,71.402-159.167,159.167-159.167s159.166,71.402,159.166,159.167c0,87.765-71.401,159.167-159.166,159.167
                                c-27.347,0-54.125-7-77.814-20.292L68.494,387.41z M154.437,337.406l4.872,2.975c20.654,12.609,44.432,19.274,68.762,19.274
                                c72.877,0,132.166-59.29,132.166-132.167S300.948,95.321,228.071,95.321S95.904,154.611,95.904,227.488
                                c0,25.393,7.217,50.052,20.869,71.311l3.281,5.109l-12.855,45.658L154.437,337.406z"/>
                              <path style="fill:#FFFFFF;" d="M183.359,153.407l-10.328-0.563c-3.244-0.177-6.426,0.907-8.878,3.037
                                c-5.007,4.348-13.013,12.754-15.472,23.708c-3.667,16.333,2,36.333,16.667,56.333c14.667,20,42,52,90.333,65.667
                                c15.575,4.404,27.827,1.435,37.28-4.612c7.487-4.789,12.648-12.476,14.508-21.166l1.649-7.702c0.524-2.448-0.719-4.932-2.993-5.98
                                l-34.905-16.089c-2.266-1.044-4.953-0.384-6.477,1.591l-13.703,17.764c-1.035,1.342-2.807,1.874-4.407,1.312
                                c-9.384-3.298-40.818-16.463-58.066-49.687c-0.748-1.441-0.562-3.19,0.499-4.419l13.096-15.15
                                c1.338-1.547,1.676-3.722,0.872-5.602l-15.046-35.201C187.187,154.774,185.392,153.518,183.359,153.407z"/>
                            </g>
                          </g>
                          </svg>
                        </a>
                      </div>
                      <div>
                        <a :href='contact.skype'>
                          <img src='/images/skype_icon.jpg' class="skypeIcon" />
                          <!-- <div :class='skypeCSS'></div> -->
                        </a>
                      </div>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>

          <div class="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-2 mt-4 sm:mt-16">
            <div class="space-y-5 sm:space-y-4">
              <!-- <h2 class="text-2xl sm:text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl mb-2">Company Info</h2> -->
              <!-- <p class="text-xl leading-7 text-gray-500">We're happy to help. And please remember, trade safe!</p> -->
            </div>
            <div class="lg:col-span-2">
              <ul class="space-y-4 sm:grid sm:grid-cols-2 sm:gap-8 sm:space-y-0 lg:col-gap-8">
                <!-- <li class='mb-8 sm:mb-0 font-bold'>Telephone &nbsp;<a
                    class='text-red-600 hover:text-red-700 hover:underline' href='tel:+31 621546725'>+31 621546725</a>
                </li> -->

                <li class='mb-8 sm:mb-0 '>
                  <!-- <div class=" text-md leading-6 space-y-1 mt-4 py-4 border-y border-gray-200"> -->
                  <a target="_blank" :href="hotel.url" class="font-medium text-lg block text-red-600">{{ hotel.name }}</a>
                  <p>{{ hotel.address }}</p>
                  <p>{{ hotel.address2 }}</p>
                  <p>phone: <a class='text-red-600' :href='hotel.phoneHref'>{{ hotel.phone }}</a></p>
                </li>
                <li></li>
                <li class='mb-8 sm:mb-0 '>
                  <span class='font-bold'>Postal Address</span><br />
                  PO Box 4011<br />
                  4900 CA Oosterhout<br />
                  The Netherlands<br />
                </li>
                <li class='mb-8 sm:mb-0 '>
                  <span class='font-bold'>Office Address</span><br />
                  Ginnekenweg 272<br />
                  4835 NK Breda<br />
                  The Netherlands<br />
                </li>
                <li class='mb-8 sm:mb-0 '>
                  <span class='font-bold'>Banking Information</span><br />
                  <span class='text-gray-500 uppercase tracking-tight text-sm'>Bank name:</span> Van Lanschot Kempen, Breda, NL<br />
                  <span class='text-gray-500 uppercase tracking-tight text-sm'>Swift:</span> FVLBNL22<br />
                  <span class='text-gray-500 uppercase tracking-tight text-sm'>IBAN:</span> NL90FVLB0225666375<br />
                </li>
                <li class='mb-8 sm:mb-0 '>
                  <span class='font-bold'>Company Information</span><br />
                  <span class='text-gray-500 uppercase tracking-tight text-sm'>Chambers of Commerce:</span> Breda, NL<br />
                  <span class='text-gray-500 uppercase tracking-tight text-sm'>Chambers Number:</span> 20094175<br />
                  <span class='text-gray-500 uppercase tracking-tight text-sm'>VAT:</span> NL810851441B01<br />
                </li>
              </ul>
            </div>
          </div>

        </div>
        <modal-contact ref='contactModal' action='sendMailToTBS'></modal-contact>
      </div>

    </template>
  </base-layout>
</template>

<script>
import _ from "lodash";
import BaseLayout from "../components/BaseLayout.vue";
import ModalContact from '@/components/ModalContact.vue'

export default {
  name: "Register",
  components: {
    BaseLayout,
    ModalContact,
  },
  data: () => ({
    hotel: {
      name: "Clayton Hotel Burlington Road",
      address: "Leeson Street Upper",
      address2: "Dublin, D04 A318, Ireland",
      phone: "+353 1 618 5600",
      phoneHref: "tel:+35316185600",
      url: "https://www.google.com/maps/dir//Leeson+Street+Upper,+Dublin,+D04+A318,+Ireland/@53.3306416,-6.3311566,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x48670ebc98632949:0x9d1aebeac89a2d68!2m2!1d-6.2487565!2d53.33067?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D",
    },
    contacts: [
      {
        name: "Mario Biemans",
        email: "mario@thebrokersite.com",
        position: "Managing Director",
        skype: "skype:mario.biemans?call'",
        tel: "+31 621546725",
        telHref: "tel:+31621546725",
        picUrl: "/images/contact_mario_tn.png",
      },
      {
        name: "Brian Altman",
        email: "brian@thebrokersite.com",
        position: "Technical Director",
        skype: "skype:bgaltman?call",
        tel: "+32 468051222",
        telHref: "tel:+32468051222",
        picUrl: "/images/contact_brian_tn.png",

      },
      {
        name: "Pepe Bermúdez",
        email: "pepe@thebrokersite.com",
        position: "Sales Director",
        skype: "skype:www.thebrokersite.com?call",
        tel: "+1 3472846827",
        telHref: "tel:+13472846827",
        picUrl: "/images/contact_pepe_tn.png",
      },
      {
        name: "Rhody Plein",
        email: "rhody@thebrokersite.com",
        position: "Sales",
        skype: "skype:www.thebrokersite.com?call",
        tel: "+31 642965537",
        telHref: "tel:+31642965537",
        picUrl: "/images/contact_rhody_tn.png",

      },
      {
        name: "Mina Marasigan",
        email: "inventory@thebrokersite.com",
        position: "Back Office",
        skype: "skype:mtmarasigan?call",
        tel: "+63 9999981583",
        telHref: "tel:+639999981583",
        picUrl: "/images/contact_mina_tn.png",
      },
      {
        name: "Debie Strijker",
        email: "debie@thebrokersite.com",
        position: "Marketing",
        skype: "skype:debie-strijker?call",
        tel: "+31 683511160",
        telHref: "tel:+31683511160",
        picUrl: "/images/contact_debie_tn.png",

      },
      {
        name: "Joel Larracas",
        email: "joel@thebrokersite.com",
        position: "Back Office",
        skype: "skype:live:.cid.deb0a7ccbd910bce?call",
        tel: "+63 9255040381",
        telHref: "tel:+639255040381",
        picUrl: "/images/contact_joel_tn.png",

      },

    ],
    skypeCSS: {
      backgroundImage: "url('/images/skype_icon.jpg')",
      backgroundSize: "contain",
      width: "20px",
      height: "20px",
      float: "left",
      display: "block",
      marginTop: "2px",
    }
  }),
  methods: {
    formatWhatsAppNumber(phoneString) {
      const firstPhoneString = phoneString.split(',')[0].trim();
      const cleanedString = firstPhoneString.replace(/[^\d]/g, '');
      return cleanedString;
    }
  },
};
</script>
<style>
.skypeIcon {
  float: left;
  width: 20px;
  height: 20px;
  /* background-image: url(/images/contact_rhody_tn.png); */
  transition: opacity 0.3s;
  background-size: contain;
  display: block;
}
</style>