<template>
  <div id="app">
    <div ref="main" />
    <router-view />
  </div>
</template>

<script>
import Vue from "vue";
import Promise from 'bluebird'
import { mapActions, mapMutations, mapState } from "vuex";
import _ from "lodash";

import eventBus from "./utils/eventBus";
import snackbar from "./components/snackbar.vue";

export default {
  components: {},
  data() {
    return {
      isUserLoaded: false
    };
  },
  computed: {
    ...mapState(["user", "profilePic"]),
  },
  async created() {

    await this.$store.dispatch('checkUserData'); 
    this.loadUserFromLocalStorage();
    if (this.user) {
      await this.loadProfilePicture();
    }
    await Promise.all([this.getCountriesAll()])
    eventBus.$on("createSnackbar", (payload) => this.createSnackbar(payload));

    if (document.referrer) {
      const referrer = new URL(document.referrer).hostname;
      localStorage.setItem("referrer", referrer);
    }
  },
  methods: {
    ...mapMutations(["updateUser"]),
    ...mapActions(["loadProfilePicture", "getCountriesAll"]),

    loadUserFromLocalStorage() {
      // load user from localstorage if exists
      const userStr = localStorage.getItem("user");
      if (userStr) {
        this.$store.commit("updateUser", JSON.parse(userStr));
      }
    },
    createSnackbar(payload) {
      const ComponentClass = Vue.extend(snackbar);
      const instance = new ComponentClass({
        propsData: payload,
      });
      instance.$mount(); // pass nothing

      this.$refs.main.appendChild(instance.$el);
      setTimeout(() => {
        this.$refs.main.removeChild(instance.$el);
      }, payload.timeoutInMS || 3000);
    },
  },
};
</script>
