import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import Register from "../views/Register.vue"
import Program from "../views/Program.vue"
import Attendees from "../views/Attendees.vue"
import Contact from "../views/Contact.vue"
import Impression from "../views/Impression.vue"
import Leads from "../views/Leads.vue"
import Scan from "../views/Scan.vue"
import Scheduler from "../views/Scheduler.vue"
import Sponsors from "../views/Sponsors.vue"
import Testimonials from "../views/Testimonials.vue"
import CompanyDetail from "../views/CompanyDetail.vue"

import store from "../store"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { title: "European Broker Meeting Dublin" },
    },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: { title: "Registration for the EBM in Dublin" },
  },
  {
    path: "/program",
    name: "program",
    component: Program,
    meta: { title: "Program" },
  },
  {
    path: "/Attendees",
    name: "attendees",
    component: Attendees,
    meta: { title: "EBM Attendees" },
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: { title: "Contact us" },
  },
  {
    path: "/pictures",
    name: "impression",
    component: Impression,
    meta: { title: "Pictures of the European Broker Meetings" },
  },
  {
    path: "/leads",
    name: "leads",
    component: Leads,
    meta: { title: "Your Leads" },
  },
  {
    path: "/scan",
    name: "scan",
    component: Scan,
    meta: { title: "Scan Leads" },
  },
  {
    path: "/scheduler",
    name: "scheduler",
    component: Scheduler,
    meta: { title: "Your Scheduler" },
  },
  {
    path: "/sponsors",
    name: "sponsors",
    component: Sponsors,
    meta: { title: "The EBM Sponsors" },
  },
  {
    path: "/testimonials",
    name: "testimonials",
    component: Testimonials,
    meta: { title: "Testimonials of the EBM over the years" },
  },
  {
    path: "/attendeeDetails/:id",
    name: "companyDetail",
    component: CompanyDetail,
    meta: { title: "Attendee details" },
  },
  {
    path: "/profile",
    name: "profile",
    component: CompanyDetail,
    meta: { title: "Company Profile" },
  },
]

const router = new VueRouter({
  mode: "history",
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  store.dispatch("getRandomBanners")
  document.title = to.meta.title || 'European Broker Meeting'
  next()
  // store.dispatch("getNewsUnread");
  // store.dispatch("logPage", {path: to.fullPath});
  // store.dispatch("getLastInvoiceStatus");

  // Google Analytics send manual pageview on each route-change
  window.gtag('event', 'page_view', {
    page_title: to.meta.title,
    page_location: to.fullPath,
    page_path: to.fullPath,
    send_to: 'G-EWFWP0D3GR'
  })

  next()
})

// Add a navigation guard --> This is added for Google Tag Manager
router.afterEach((to, from) => {
  // Assuming window.dataLayer exists
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'pageview',
    'page': to.path,
    'title': document.title,
  });
});

export default router
